<template>
  <div class="container mb-5">
    <div id="blocking-overlay" v-if="waitingForResponse">
      <div id="blocking-spinner" class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
        </div>
      </div>
    </div>
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <a class="navbar-brand" href="https://bayesfusion.com/">
          <img style="height: 3.5rem; margin-right: 1rem" :src="logo">
          <span>BayesFusion Probability Distribution Visualizer</span>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse order-3" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <a class="nav-link" href="https://metalog.bayesfusion.com/">Metalog Builder</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" id="useful-links" href="#" role="button" @click="toggleLinksFocus" @blur="blurLinks">
                Useful Links
              </a>
              <ul class="dropdown-menu bg-light" :style="`display:${displayLinks ? 'block' : 'none'}`">
                <li><a class="dropdown-item" href="https://bayesfusion.com">Main BayesFusion Website</a></li>
                <li><a class="dropdown-item" href="https://repo.bayesfusion.com">Interactive Model Repository</a></li>
                <li><a class="dropdown-item" href="https://support.bayesfusion.com/docs">Documentation</a></li>
                <li><a class="dropdown-item" href="https://support.bayesfusion.com/forum">Support Forum</a></li>
                <li><a class="dropdown-item" href="https://www.youtube.com/c/bayesfusion">YouTube Channel</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click="showHelpModal">Help <i class="bi bi-question-square"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="row">
      <div class="area-menu col-12 col-lg-9">
        <div class="row">
          <div class="col-12 instruction mb-2">Type any <span :class="`${expressionGlow ? 'glow' : ''}`" @mouseover="expressionGlow = true" @mouseleave="expressionGlow = false" class="badge text-bg-info">Expression</span> containing supported <span :class="`${functionsGlow ? 'glow' : ''}`" @mouseover="functionsGlow = true" @mouseleave="functionsGlow = false" class="badge text-bg-info">Random Number Generator Functions</span>, set the <span :class="`${parametersGlow ? 'glow' : ''}`" @mouseover="parametersGlow = true" @mouseleave="parametersGlow = false" class="badge text-bg-info">Sampling Parameters</span>, then click <span :class="`${recalcGlow ? 'glow' : ''}`" @mouseover="recalcGlow = true" @mouseleave="recalcGlow = false" class="badge text-bg-info">Recalc</span> to display the resulting samples. For more info, see <a href="https://support.bayesfusion.com/docs/GeNIe/index.html?functions_random_number_generators.html" target="_blank">GeNIe Manual</a>.</div>
          <div :class="`col-12 ${expressionGlow ? 'expression-glow' : ''}`">
            <code-input id="expression-textarea" placeholder="" ref="code-input" class="form-control expression match-braces" :value="expressionString" @input="updateExpressionFromCodeInput()" lang="prob"></code-input>
            <div v-if="error" class="col-12 alert alert-danger alert-dismissible fade show mt-1 mb-1" role="alert">
              <span class="m-1">{{errorValue.errorMessage}}</span><button type="button" class="btn btn-outline-danger close-button" aria-label="Close" @click="error = false">
              <span aria-hidden="true">&times;</span>
            </button>
            </div>
          </div>
          <form class="form-floating col-6 col-md-3 mt-2">
            <input type="text" :class="`form-control ${this.lowerBoundValid ? '' : 'is-invalid'} ${parametersGlow ? 'glow' : ''}`" id="lower-bound-input" placeholder="-inf" v-model="lowerBound"
                   data-bs-toggle="popover"
                   data-bs-placement="top"
                   data-bs-content=""
                   @focusout="removePopoverText('lower-bound-input')">
            <label for="lower-bound-input">Lower Bound</label>
          </form>
          <form class="form-floating col-6 col-md-3 mt-2">
            <input type="text" :class="`form-control ${this.upperBoundValid ? '' : 'is-invalid'} ${parametersGlow ? 'glow' : ''}`" id="upper-bound-input" placeholder="inf" v-model="upperBound"
                   data-bs-toggle="popover"
                   data-bs-placement="top"
                   data-bs-content=""
                   @focusout="removePopoverText('upper-bound-input')">
            <label for="upper-bound-input">Upper Bound</label>
          </form>
          <form class="form-floating col-6 col-md-3 mt-2">
            <input type="text" :class="`form-control ${this.sampleCountValid ? '' : 'is-invalid'} ${parametersGlow ? 'glow' : ''}`" id="sample-count-input" placeholder="10000" v-model="sampleCount"
                   data-bs-toggle="popover"
                   data-bs-placement="top"
                   data-bs-content=""
                   @focusout="removePopoverText('sample-count-input')">
            <label for="sample-count-input">Sample Count</label>
          </form>
          <form class="form-floating col-6 col-md-3 mt-2">
            <input type="text" :class="`form-control ${this.randomSeedValid ? '' : 'is-invalid'} ${parametersGlow ? 'glow' : ''}`" id="random-seed-input" placeholder="0" v-model="randomSeed"
                   data-bs-toggle="popover"
                   data-bs-placement="top"
                   data-bs-content=""
                   @focusout="removePopoverText('random-seed-input')">
            <label for="random-seed-input" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Seed equal to zero means true randomness.">Random Seed</label>
          </form>
        </div>
        <div class="col-7 col-lg-2 d-grid mx-auto mt-2">
          <button id="recalc-btn" :class="`btn btn-lg btn-primary ${recalcGlow ? 'glow' : ''}`" @click="fetchData()">Recalc</button>
        </div>
      </div>
      <div :class="`accordion functions-accordion mt-1 col-lg-3 col-12 ${functionsGlow ? 'glow' : ''}`" id="functionsAccordion">
        <div class="accordion-item" v-for="(functionType, idx) in functionTypes" :key="functionType">
          <div class="accordion-header" :id="`heading-${idx}`">
            <button class="accordion-button py-2 px-2 collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse-${idx}`" aria-expanded="false" :aria-controls="`#collapse-${idx}`">
              {{functionType.label}}
            </button>
          </div>
          <div :id="`collapse-${idx}`" class="accordion-collapse collapse" :aria-labelledby="`heading-${idx}`" data-bs-parent="#functionsAccordion">
            <ul class="list-group list-group-flush accordion-body extra-small">
              <li v-for="func in functionType.functs" :key="func" class="list-group-item list-group-item-action py-2 px-2 clickable" @click="expressionString += func"><span class="clickable">{{ func }}</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 mt-1">
        <div class="input-group">
          <span class="input-group-text">Sample Stats:</span>
          <input class="form-control bg-light" readonly :value="`Mean=${graphParams.mean.toFixed(fractionDigits)} StdDev=${graphParams.stdDev.toFixed(fractionDigits)} Skewness=${graphParams.skewness.toFixed(fractionDigits)} Kurtosis=${graphParams.kurtosis.toFixed(fractionDigits)} Min=${graphParams.min.toFixed(fractionDigits)} Max=${graphParams.max.toFixed(fractionDigits)} Count=${sortedData.length}`"/>
          <button class="btn btn-secondary" type="button" @click="copySampleStatsToClipboard" title="Copy To Clipboard"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
          </svg></button>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-lg-3 my-lg-auto mb-2">
        <div class="btn-group btn-group-sm btn-toolbar" role="group" aria-label="">
          <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" @click="graphStyle = 'bar'" checked>
          <label class="btn btn-outline-primary" for="btnradio1">Bar Graphs</label>

          <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" @click="graphStyle = 'line'">
          <label class="btn btn-outline-primary" for="btnradio2">Line Graphs</label>
        </div>
      </div>
      <span class="col-2 col-lg-1 my-auto">Bins: {{ binCount }}</span>
      <div class="col-10 col-lg-4 ml-0 mt-auto"><input type="range" class="form-range my-auto" min="2" max="100" step="1" id="bin-range" @change="bucketCountChanged" v-model="binCount"></div>
      <div class="col-lg-2"></div>
      <div class="modal modal-xl fade" id="helpModal" tabindex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5>
                Welcome to BayesFusion Probability Distribution Visualizer
              </h5>
            </div>
            <div class="modal-body small">
              <span>
                Choosing the right probability distribution over continuous data is a skill that requires some statistical insight. When the distribution is transformed by an equation expression, the task is daunting even for an experienced decision analyst. Probability Distribution Visualizer is an interactive tool for visualizing expressions with probability distributions through Monte Carlo simulation. This helps with selecting an expression that is most appropriate for a task at hand.
                <br><br>
                While the equation may only contain predefined functions, the set of predefined functions is comprehensive and there are practically no limitations on the functional form and on the distributions used.  We will love to hear about possible extensions to the set of functions and distributions.  This page mimics the functionality of the Distribution Visualizer window in GeNIe.  For more information, please refer to the <a href="https://support.bayesfusion.com/docs/GeNIe/index.html?equations_distribution-visualizer.html" target="_blank">GeNIe Modeler manual page for the Distribution Visualizer</a>.
                <br><br>
                To those users who face the task of fitting a distribution to data, we recommend the interactive <a href="https://metalog.bayesfusion.com">Metalog Builder</a> companion page. Metalog distributions, included in the set of predefined probability distributions in GeNIe, are known for their unparalleled flexibility.
                <br><br>
                The backend of this application runs on SMILE, BayesFusion's cross-platform library for reasoning and learning/causal discovery engine for graphical models, such as Bayesian networks, influence diagrams, and structural equation models. For more information on SMILE and GeNIe visit our website at <a href="https://www.bayesfusion.com">www.bayesfusion.com</a>.
              </span>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3 mb-5">
      <div class="col-lg-6">
        <canvas id="probability-density-bar" :style="graphStyle === 'bar' ? '' : 'display:none'"></canvas>
        <canvas id="probability-density-line" :style="graphStyle === 'line' ? '' : 'display:none'"></canvas>
      </div>
      <div class="col-lg-6">
        <canvas id="cumulative-density-bar" :style="graphStyle === 'bar' ? '' : 'display:none'"></canvas>
        <canvas id="cumulative-density-line" :style="graphStyle === 'line' ? '' : 'display:none'"></canvas>
      </div>
    </div>

    <div class="footer navbar fixed-bottom bg-white container">
      <div class="container-fluid">
        <div class="navbar order-3">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <a class="nav-link footer-contact" href="https://bayesfusion.com/">© Copyright {{new Date().getFullYear()}} BayesFusion, LLC</a>
            </li>
          </ul>
        </div>
        <div class="navbar order-3">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <a class="nav-link" href="https://www.bayesfusion.com/contact/" >Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import axios from "axios";
import {bucketizeSortedBinary} from "@/bucketize";
import {shallowRef} from "vue";
import {initExpression, initHiBound, initLoBound, initSamples, initMin, initMax, initMean, initSkewness, initKurtosis, initStdDev} from "@/initData";
import Prism from "prismjs";
import * as prismMatchBraces from "prismjs/plugins/match-braces/prism-match-braces"
import "prismjs/themes/prism.css";
import "prismjs/plugins/match-braces/prism-match-braces.css"
import "@/code-input/code-input.css"
import {codeInput} from "@/code-input/code-input";
import { useCookies } from "vue3-cookies";

const bootstrap = require("bootstrap/dist/js/bootstrap");

codeInput.registerTemplate("syntax-highlighted", codeInput.templates.prism(Prism, [prismMatchBraces] /* Array of plugins (see below) */));

const isInteger = num => /^-?[0-9]+$/.test(num+'');
const isNumeric = num => /^-?[0-9]+(?:\.[0-9]+)?$/.test(num+'');
const DATASET_COLOR = "rgba(13,110,253)";

function sortData(rawData) {
  const typedData = Float32Array.from(rawData);
  typedData.sort();
  return typedData;
}

function updateChart(chart, data, options = null) {
  chart.data = data;
  if (options !== null) {
    chart.options = options;
  }
  chart.update();
}

function setCaretPosition(id, caretPosition = 0) {
  const el = document.getElementById(id);
  el.selectionStart = caretPosition;
  el.selectionEnd = caretPosition;
  el.blur();
}

function chartTemplate(labelsX, tooltips, dataSet, dataCount)  {
  return {
    data: {
      labels: labelsX,
      datasets: [ dataSet ]
    },
    options: {
      chartArea: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            title: function() {
              return "";
            },
            label: function(context) {
              return tooltips[context.dataIndex]
            }
          }
        }
      },
      scales: {
        y: {
          ticks: {
            callback: function(val) {
              return `${(val / dataCount * 100).toFixed(2)}%`
            }
          }
        },
        x: {
          ticks: {
            callback: function(idx) {
              return `${labelsX[idx]}`
            }
          },
          grid: {
            offset: false
          }
        },
      }
    }
  }
}

function bootstrapClass(px) {
  if (px < 576) {
    return "xs";
  } else if (px < 768) {
    return "sm";
  } else if (px < 992) {
    return "md";
  } else if (px < 1200) {
    return "lg"
  }
  return "xl";
}

function getOS() {
  var userAgent = window.navigator.userAgent,
      platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

function showPopoverAtElement(id, text) {
  document.getElementById(id).setAttribute("data-bs-content", text);
  setTimeout(() => {
    document.getElementById(id).focus();
  }, 100); // function must be delayed to show properly
}

const popoverErr = {
  lowerBound: (text = "") => {
    const id = "lower-bound-input";
    showPopoverAtElement(id, text);
  },
  upperBound: (text = "") => {
    const id = "upper-bound-input";
    showPopoverAtElement(id, text);
  },
  sampleCount: (text = "") => {
    const id = "sample-count-input";
    showPopoverAtElement(id, text);
  },
  randomSeed: (text = "") => {
    const id = "random-seed-input";
    showPopoverAtElement(id, text);
  },
  expression: (text = "") => {
    const id = "expression-input";
    showPopoverAtElement(id, text);
  }
}

function removePopoverText(id) {
  document.getElementById(id).setAttribute("data-bs-content", "");
  let popovers = document.getElementsByClassName("popover");
  for (let i = popovers.length - 1; i >= 0; i -= 1) {
    popovers.item(i).remove();
  }
}

export default {
  name: 'App',
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      displayLinks: false,
      expressionGlow: false,
      functionsGlow: false,
      recalcGlow: false,
      parametersGlow: false,
      fractionDigits: 6,
      waitingForResponse: false,
      error: false,
      errorValue: {
        errorCode: 0,
        errorMessage: ""
      },
      windowClass: bootstrapClass(window.innerWidth),
      functionTypes: [
        { value: "random_number_generators", label: "Random number generators", functs: ["Bernoulli(p)", "Beta(a, b)", "Binomial(n, p)", "CustomPDF(x1, x2, ... y1, y2, ...)", "Discrete(v1, v2, ... p1, p2, ...)", "Exponential(lambda)", "Gamma(shape, scale)", "Lognormal(mu, sigma)", "Metalog(lower, upper, k, x1, x2, ..., y1, y2, ...)", "MetalogA(lower, upper, a1, a2, ...)", "Normal(mu, sigma)", "Poisson(lambda)", "Steps(x1, x2, ..., y1, y2, ...)", "Triangular(min, mod, max)", "TruncNormal(mu, sigma, lower, [upper])", "Uniform(lower, upper)", "Weibull(lambda, k)"] },
        { value: "statistical_functions", label: "Statistical functions", functs: ["Erf(x)", "NormalCDF(x, mu, sigma)", "NormalPDF(x, mu, sigma)"] },
        { value: "arithmetic_functions", label: "Arithmetic functions", functs: ["Abs(x)", "Exp(x)", "Gammaln(x)", "GCD(n, k)", "Inf()", "LCM(n, k)", "Ln(x)", "Log(x, b)", "Log10(x)", "Mod(x, y)", "Pi()", "Pow10(x)", "Round(x)", "Sign(x)", "Sqr(x)", "Sqrt(x)", "SqrtPi(x)", "Sum(x1, x2, ...)", "SumSq(x1, x2, ...)", "Trim(x, lo, hi)", "Truncate(x)"] },
        { value: "combinatoric_functions", label: "Combinatoric functions", functs: ["Combin(n, k)", "Fact(n)", "FactDouble(n)", "Multinomial(n1, n2, ...)"] },
        { value: "trigonometric_functions", label: "Trigonometric functions", functs: ["Acos(x)", "Asin(x)", "Atan(x)", "Atan2(y, x)", "Cos(x)", "Pi()", "Sin(x)", "Tan(x)"] },
        { value: "hyperbolic_functions", label: "Hyperbolic functions", functs: ["Cosh(x)", "Sinh(x)", "Tanh(x)"] },
        { value: "conditional_functions", label: "Conditional functions", functs: ["Choose(index, v1, v2, ...)", "If(cond, tval, fval)", "Switch(x, a1, b1, a2, b2, ...[def])"] },
        { value: "logical_functions", label: "Logical functions", functs: ["And(b1, b2, ...)", "Max(x1, x2, ...)", "Min(x1, x2, ...)", "Or(b1, b2, ...)", "Xor(b1, b2, ...)"] },
        { value: "operators", label: "Operators", functs: ["^", "*", "/", "+", "-", ">", "<", ">=", "<=", "<>", "=", "?:"] }
      ],
      selectedFunctionType: { value: "", label: "Function Types", functs: []},
      expressionString: "Normal(0,1)",
      upperBound: 5,
      lowerBound: -5,
      sampleCount: 1000,
      randomSeed: 0,
      graphStyle: "bar",
      charts: {
        bar: {
          probabilityDensity: null,
          cumulativeDensity: null
        },
        line: {
          probabilityDensity: null,
          cumulativeDensity: null
        }
      },
      sampleStats: {
        mean: 0,
        stdDev: 0,
        skewness: 0,
        kurtosis: 0,
        min: 0,
        max: 0,
        count: 10000
      },
      binCount: 50,
      sortedData: [],
      graphParams: {
        upperBound: 0,
        lowerBound: 0,
        expression: "",
        mean: 0,
        stdDev: 0,
        skewness: 0,
        kurtosis: 0,
        min: 0,
        max: 0,
        count: 0
      },
      bucketizedData: [],
      logo: require("@/assets/bf-logo.png"),
    }
  },
  computed: {
    functionRegex: {
      get() {
        let result = "\\b(";
        result += this.functionTypes.filter((f) => (f.value !== "operators")).map((f) => (f.functs.map((ff) => (ff.slice(0, ff.indexOf("("))))).join("|")).join("|");
        result += ")\\b";
        return result;
      }
    },
    notFunctionRegex: {
      get() {
        let result = "(?!(";
        result += this.functionTypes.filter((f) => (f.value !== "operators")).map((f) => (f.functs.map((ff) => (ff.slice(0, ff.indexOf("("))))).join("|")).join("|");
        result += ")\\b)\\b\\w+(?=\\()";
        return result;
      }
    },
    probabilityDensity: {
      get() {
        const labelsX = this.bucketizedData.map((el) => `${((el.min + el.max) / 2).toFixed(2)}`);
        const dataSet = {
          label: "Probability density",
          data: this.bucketizedData.map((el) => el.count),
          backgroundColor: DATASET_COLOR,
          borderColor: DATASET_COLOR
        };
        const tooltips = this.bucketizedData.map((el) => `${el.count} data points (${el.count * 100 / this.sortedData.length}%) in (${el.min.toFixed(2)}, ${el.max.toFixed(2)})`)
        return chartTemplate(labelsX, tooltips, dataSet, this.sortedData.length);
      }
    },
    recalcShortcut: {
      get() {
        const os = getOS();
        if (os === "Mac OS") {
          return "[Alt/Option + R]";
        } else if (os === "Windows" || os === "Linux") {
          return "[Alt + R]";
        }
        return "";
      }
    },
    cumulativeDensity: {
      get() {
        const labelsX = this.bucketizedData.map((el) => `${((el.min + el.max) / 2).toFixed(2)}`);
        let sum = 0;
        const dataSet = {
          label: "Cumulative density",
          data: this.bucketizedData.map((el) => sum += el.count),
          backgroundColor: DATASET_COLOR,
          borderColor: DATASET_COLOR
        };
        const tooltips = dataSet.data.map((el, idx) => `${el} data points (${el * 100 / this.sortedData.length}%) up to (${this.bucketizedData[idx].max.toFixed(2)})`)
        return chartTemplate(labelsX, tooltips, dataSet, this.sortedData.length);
      }
    },
    lowerBoundValid: {
      get() {
        return (isNumeric(this.lowerBound) || this.lowerBound === "-inf" || this.lowerBound === "");
      }
    },
    upperBoundValid: {
      get() {
        return (isNumeric(this.upperBound) || this.upperBound === "inf" || this.upperBound === "");
      }
    },
    sampleCountValid: {
      get() {
        return (isInteger(this.sampleCount) && this.sampleCount > 0 && this.sampleCount <= 100000);
      }
    },
    randomSeedValid: {
      get() {
        return isInteger(this.randomSeed);
      }
    },
    upperBoundGreaterThanLowerBound: {
      get() {
        if ((this.lowerBound === "-inf" || this.lowerBound === "") || (this.upperBound === "inf" || this.upperBound === "")) {
          return true;
        }
        if (isNumeric(this.lowerBound) && isNumeric(this.upperBound)) {
          return true;
        }
        return false;
      }
    }
  },
  methods: {
    removePopoverText(id) {
      removePopoverText(id);
    },
    validateData() {
      const errors = {
        sampleCount: "Sample count must be an integer in range (0, 100000>. ",
        randomSeed: "Random seed must be an integer. ",
        lowerBound: "Lower bound must be a numeric value or negative infinity (\"-inf\") ",
        upperBound: "Upper bound must be a numeric value or infinity (\"inf\") ",
        upperBoundGreaterThanLowerBound: "Upper bound must be greater than lower bound."
      };
      if (this.lowerBound === "") {
        this.lowerBound = "-inf";
      }
      if (this.upperBound === "") {
        this.upperBound = "inf";
      }

      if (!this.lowerBoundValid) {
        popoverErr.lowerBound(errors.lowerBound);
      } else if (!this.upperBoundValid) {
        popoverErr.upperBound(errors.upperBound);
      } else if (!this.upperBoundGreaterThanLowerBound) {
        popoverErr.upperBound(errors.upperBoundGreaterThanLowerBound);
      } else if (!this.sampleCountValid) {
        popoverErr.sampleCount(errors.sampleCount);
      } else if (!this.randomSeedValid) {
        popoverErr.randomSeed(errors.randomSeed);
      } else {
        return false;
      }
      return true;
    },
    fetchData() {
      this.error = false;
      this.waitingForResponse = true;
      if (this.validateData()) {
        this.waitingForResponse = false;
        return;
      }
      let postData = {
        sampleCount: Number(this.sampleCount),
        expression: this.expressionString,
        seed: Number(this.randomSeed)
      }
      if (isNumeric(this.lowerBound)) {
        postData.loBound = Number(this.lowerBound);
      }
      if (isNumeric(this.upperBound)) {
        postData.hiBound = Number(this.upperBound);
      }
      axios.post("https://prob.bayesfusion.com/distribution", postData)
      .then((response) => {
        this.sortedData = sortData(response.data.samples);
        this.graphParams = {
          upperBound: this.upperBound,
          lowerBound: this.lowerBound,
          expression: this.expressionString,
          min: response.data.min,
          max: response.data.max,
          mean: response.data.mean,
          stdDev: response.data.stdDev,
          skewness: response.data.skewness,
          kurtosis: response.data.kurtosis
        }
        this.bucketizedData = bucketizeSortedBinary(this.sortedData, this.graphParams.min, this.graphParams.max, this.binCount);
        this.updateCharts();
      }).catch((err) => {
        // this.error = true;
        console.log(err.response);
        setCaretPosition("expression-input", err.response.data.errorPosition);
        popoverErr.expression(err.response.data.errorMessage);
      }).finally(() => {
        this.waitingForResponse = false;
      });
    },
    updateCharts() {
      const probDens = this.probabilityDensity;
      if (this.charts.bar.probabilityDensity === null) {
        this.charts.bar.probabilityDensity = shallowRef(new Chart(document.getElementById("probability-density-bar"), {...probDens, type: "bar"}));
      } else {
        updateChart(this.charts.bar.probabilityDensity, probDens.data, probDens.options);
      }
      if (this.charts.line.probabilityDensity === null) {
        this.charts.line.probabilityDensity = shallowRef(new Chart(document.getElementById("probability-density-line"), {...probDens, type: "line"}));
      } else {
        updateChart(this.charts.line.probabilityDensity, probDens.data, probDens.options);
      }
      const cumulativeDens = this.cumulativeDensity;
      if (this.charts.bar.cumulativeDensity === null) {
        this.charts.bar.cumulativeDensity = shallowRef(new Chart(document.getElementById("cumulative-density-bar"), {...cumulativeDens, type: "bar"}));
      } else {
        updateChart(this.charts.bar.cumulativeDensity, cumulativeDens.data, cumulativeDens.options);
      }
      if (this.charts.line.cumulativeDensity === null) {
        this.charts.line.cumulativeDensity = shallowRef(new Chart(document.getElementById("cumulative-density-line"), {...cumulativeDens, type: "line"}));
      } else {
        updateChart(this.charts.line.cumulativeDensity, cumulativeDens.data, cumulativeDens.options);
      }
    },
    bucketCountChanged() {
      this.bucketizedData = bucketizeSortedBinary(this.sortedData, this.graphParams.lowerBound, this.graphParams.upperBound, this.binCount);
      this.updateCharts();
    },
    onResize() {
      this.windowClass = bootstrapClass(window.innerWidth);
    },
    copySampleStatsToClipboard() {
      const statsText = `Mean=${this.graphParams.mean}  StdDev=${this.graphParams.stdDev}  Skewness=${this.graphParams.skewness}  Kurtosis=${this.graphParams.kurtosis}  Min=${this.graphParams.min}  Max=${this.graphParams.max}  Count=${this.sortedData.length}`;
      navigator.clipboard.writeText(statsText);
    },
    toggleFunctionType(f) {
      this.selectedFunctionType = f === this.selectedFunctionType ? null : f;
    },
    toggleLinksFocus() {
      this.displayLinks = !this.displayLinks;
      if (this.displayLinks) {
        document.getElementById("useful-links").focus();
      }
    },
    blurLinks() {
      if (this.displayLinks) {
        setTimeout(() => {
          document.getElementById("useful-links").blur();
          this.displayLinks = false;
        }, 100); // Safari workaround - blur is always dispatched before click - even on the same element
      }
    },
    updateExpressionFromCodeInput() {
      this.expressionString = this.$refs["code-input"].value;
    },
    adjustCodeInput() {
      const textarea = this.$refs["code-input"].children[0];
      const pre = this.$refs["code-input"].children[1];
      const code = pre.children[0];
      textarea.setAttribute("placeholder", "");
      textarea.setAttribute("id", "expression-input");
      code.setAttribute("class", "language-prob rainbow-braces match-braces");
      textarea.setAttribute("data-bs-toggle", "popover");
      textarea.setAttribute("data-bs-placement", "bottom");
      textarea.setAttribute("data-bs-content", "");
      textarea.addEventListener("focusout", () => {
        removePopoverText("expression-input");
      });
    },
    showHelpModal() {
      this.cookies.set("visitedBefore", "true", 60 * 60 * 24 * 30);
      let helpModal = new bootstrap.Modal(document.getElementById('helpModal'));
      helpModal.show();

    },
    checkVisitedCookie() {
      return this.cookies.isKey("visitedBefore");
    }
  },
  mounted() {
    const plugin = {
      id: 'custom_canvas_background_color',
      beforeDraw: (chart, args, options) => {
        const {ctx} = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color;
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      },
      defaults: {
        color: 'white'
      }
    }
    Chart.register(plugin);
    this.$nextTick(() => {
      const ctx = this;
      window.addEventListener('resize', this.onResize);
      window.onkeyup = function (e) {
        if (e.ctrlKey && e.code === "Enter" && document.activeElement.id === "expression-input") {
          ctx.fetchData();
        }
      }
    });
    this.lowerBound = "-inf";
    this.upperBound = "inf";
    this.expressionString = initExpression;
    this.sortedData = sortData(initSamples);
    this.sampleCount = this.sortedData.length;
    this.graphParams = {
      lowerBound: initLoBound,
      upperBound: initHiBound,
      expression: initExpression,
      min: initMin,
      max: initMax,
      mean: initMean,
      stdDev: initStdDev,
      skewness: initSkewness,
      kurtosis: initKurtosis
    };
    this.bucketizedData = bucketizeSortedBinary(this.sortedData, this.graphParams.lowerBound, this.graphParams.upperBound, this.binCount);
    this.updateCharts();
    this.adjustCodeInput();
    let probLang = { ... Prism.languages.javascript }
    probLang.function = new RegExp(this.functionRegex, "i");
    probLang.keyword = new RegExp(this.notFunctionRegex, "i");
    probLang.comment = null;
    probLang["class-name"] = null;
    probLang.constant = null;
    Prism.languages.prob = probLang;
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    // eslint-disable-next-line no-unused-vars
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
    if (!this.checkVisitedCookie()) {
      this.showHelpModal();
    }
  },
  beforeUpdate() {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    // eslint-disable-next-line no-unused-vars
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl, {
        trigger: 'focus'
      })
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background-color: transparent !important;
}

.footer {
  --bs-navbar-padding-y: 0 !important;
  height: 3rem;
}

.navbar-brand, .navbar-brand:focus, .navbar-brand:hover {
  color: rgba(0,0,0,0.9);
}

.nav-link:focus, .nav-link:hover {
  color: #4c4c4c !important;
}

.nav-link {
  color: #737373 !important;
}

.footer-contact {
  padding: 0 0.5rem 0.5rem 0.5rem !important;
}

.token.function {
  color: #89b4d1 !important;
}

.token.number {
  color: #333 !important;
}

.token.keyword {
  color: #d00 !important;
}

.token.string {
  color: #000 !important;
}

.token.brace-level-1 {
  color: #2266ff !important;
}
.token.brace-level-2 {
  color: #00bb33 !important;
}
.token.brace-level-3 {
  color: #ee00ee !important;
}
.token.brace-level-4 {
  color: #4c4c4c !important;
}
.token.brace-level-5 {
  color: #2266ff !important;
}
.token.brace-level-6 {
  color: #00bb33 !important;
}
.token.brace-level-7 {
  color: #ee00ee !important;
}
.token.brace-level-8 {
  color: #4c4c4c !important;
}
.popover-body {
  background-color: #dc3545 !important;
  color: white !important;;
}
.popover {
  --bs-popover-bg: #dc3545 !important;
}
</style>

<style scoped>
code-input {
  height: 8.5rem;
  max-height: 15rem;
  margin: 0px;
}
select {
  width: 100%;
}
#functions-type {
  padding: 0.5rem;
}

option {
  padding-top: 0.2rem
}

.clickable {
  cursor: pointer;
}

.input-group-text {
  width: 7rem;
  padding-left: 0.375rem;
  padding-right: 0.1rem;
}
.form-control {
  padding-left: 0.3rem;
  padding-right: 0.1rem;
}
.hidden {
  display: none;
}
#recalc-btn {
  white-space: pre;
}
.dropdown-toggle {
  white-space: normal;
}
.area-menu {
  margin: auto auto 0;
}
.ma {
  margin: auto;
}
.close-button {
  position: absolute;
  top: 0;
  right: 0;
}

#blocking-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1111;
  cursor: wait;
}

#blocking-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
}

.blur {
  filter: blur(1.5rem);
}

.card-header {
  padding: 0;
}

.functions-accordion {
  max-height: 19.5rem;
  overflow-y: auto;
  padding: 0.0rem;
}

.functions-accordion-btn {
  width:100%;
  display: flex;
  justify-content: space-between;
}

.instruction {
  margin: auto auto;
  text-align: left;
}

.glow {
  border-color:  rgba(82,168,236,.8);
  box-shadow: 0 0px 0px rgba(82,168,236,.8) inset, 0 0 8px rgba(82,168,236,.8);
  outline: 0 none;
}

.expression-glow #expression-textarea {
  border-color:  rgba(82,168,236,.8);
  box-shadow: 0 0px 0px rgba(82,168,236,.8) inset, 0 0 8px rgba(82,168,236,.8);
  outline: 0 none;
}

#expression-textarea {
  font-weight: bold;
}

#expression-textarea > textarea {
  font-weight: bold;
}

.text-bg-info {
  background-color: #cff4fc !important;
  color: #055160 !important;
}

.dropdown-menu:hover, .dropdown-menu:active {
  display: block !important;
}

.modal-body {
  text-align: left;
}

.accordion-body {
  padding: 0 !important;
  text-align: left;
}

.list-group-item {
  text-align: center;
}

.form-floating > label {
  left: 0.3rem;
}

.functions-accordion {
  padding: 0.2rem;
}

.extra-small {
  font-size: 0.75rem;
}

code-input {
  font-size: 1.2rem;
}


</style>
