function bucketizeSortedLinear(sortedData, _min, _max, bucketCount = 2) {
  const min = _min < sortedData[0] ? sortedData[0] : _min;
  const max = _max > sortedData[sortedData.length - 1] ? sortedData[sortedData.length - 1] : _max;
  console.log(`bucket min${min} max${max}`)
  const bucketSize = (max - min) / bucketCount;
  console.log(`bucket size${bucketSize}`)
  let buckets = [];
  for (let i = min; i < max; i += bucketSize) {
    const firstIndex = sortedData.findIndex((el) => el >= i);
    let lastIndex = sortedData.findIndex((el) => el > i + bucketSize);
    lastIndex = lastIndex === -1 ? sortedData.length - 1 : lastIndex;
    console.log(`firstIndex:${firstIndex} lastIndex:${lastIndex}`);
    buckets.push({
      min: i,
      max: i + bucketSize,
      count: lastIndex - firstIndex
    });
  }
  return buckets;
}

function next(arr, target)
{
    let start = 0, end = arr.length - 1;
    let ans = -1;

    while (start <= end)
    {
        let mid = parseInt((start + end) / 2, 10);

        // Move to right side if target is
        // greater.
        if (arr[mid] <= target)
        {
            start = mid + 1;
        }

        // Move left side.
        else
        {
            ans = mid;
            end = mid - 1;
        }
    }
    return ans;
}

function bucketizeSortedBinary(sortedData, _min, _max, bucketCount = 2) {
    bucketCount = Number(bucketCount);
    let buckets = Array(bucketCount).fill(0);
    const min = _min > sortedData[0] ? _min : sortedData[0];
    const max = _max < sortedData[sortedData.length - 1] ? _max : sortedData[sortedData.length - 1];
    const bucketSize = (max - min) / bucketCount;
    let firstIndex = 0;
    let lastIndex = 0;
    for (let i = 0; i < buckets.length; i++) {
        lastIndex = next(sortedData, min + bucketSize + bucketSize * i);
        lastIndex = lastIndex === -1 ? sortedData.length : lastIndex;
        buckets[i] = {
            min: min + bucketSize * i,
            max: min + bucketSize * i + bucketSize,
            count: lastIndex - firstIndex
        }
        firstIndex = lastIndex;
    }
    return buckets;
}

function bucketizeUnsorted(rawData, _min, _max, binCount = 2) {
  let buckets = Array(binCount).fill(0);
  const interval = _max - _min;
  for (let i = 0; i < rawData.length; i++) {
    let binIndex = binCount - 1;
    if (rawData[i] < _max) {
      binIndex = (binCount * (rawData[i] - _min) / interval) | 0;
      if (binIndex === binCount) {
        binIndex -= 1;
      }
    }
    buckets[binIndex] += 1;
  }
  return buckets;
}

export {bucketizeUnsorted, bucketizeSortedBinary, bucketizeSortedLinear}